<template>
  <div class="reward" :class="{lan_En : lan != 'zh'}">
    <div class="reward_icon1"></div>
    <div class="node_desc">
      <div class="desc_left animation_hide" v-animate="{delay: 0, class:'fadeInLeft'}">
        <div class="title">{{ $t('reward2.desc.title1') }}</div>
        <div class="text">{{ $t('reward2.desc.text1') }}</div>
      </div>
      <div class="desc_right animation_hide" v-animate="{delay: 0, class:'fadeInRight'}">
        <div class="title">{{ $t('reward2.desc.title2') }}</div>
        <div class="right_list">
          <div class="right_li">{{ $t('reward2.desc.text2') }} <br/> 30,000 DLC</div>
          <div class="right_li">{{ $t('reward2.desc.text3') }}<br/>100,000 DLC</div>
          <div class="right_li">{{ $t('reward2.desc.text4') }}<br/>30 USD</div>
          <div class="right_li">{{ $t('reward2.desc.text5') }}<br/> 155 USD</div>
        </div>
      </div>
    </div>
    <div class="node_video animation_hide" v-animate="{delay: 0, class:'amplify'}"> 
      <video src="https://static1.deeplink.cloud/DeepLinkVideo.mp4"
        style="height: 100%; width: 100%;"
        loop="loop" 
        muted="muted" 
        controls="controls"
        controlsList="noplaybackrate, nopip">
      </video>
    </div>
    <div class="btn-shadow">
      <a href="https://www.drcpad.io/project?name=DeepLink" target="_blank" class="btn">{{ $t('reward2.btn_text') }}</a>
      <div class="shadow"></div>
    </div>
    <!-- <a href="https://www.drcpad.io/project?name=DeepLink" target="_blank" class="buy_nodes">{{ $t('reward2.btn_text') }}</a> -->
    <div class="node_intro animation_hide" v-animate="{delay: 0, class:'amplify'}">
      <div class="intro_title">{{ $t('reward2.intro.title') }}</div>
      <div class="intro_text">
        <span>1.</span>
        <p>
          {{$t('reward2.intro.text1_1')}}
          <a href="https://discord.com/invite/hCSAF3QC8U"> Discord </a>
          {{$t('reward2.intro.text1_2')}}
          <a href="https://t.me/deeplinkglobal"> Telegram </a>
          {{$t('reward2.intro.text1_3')}}
        </p>
      </div>
      <div class="intro_text">
        <span>2.</span>
        <p>{{$t('reward2.intro.text2')}}</p>
      </div>
      <div class="intro_text"><span>3.</span><p>{{$t('reward2.intro.text3')}}</p></div>
      <div class="intro_text"><span>4.</span><p>{{$t('reward2.intro.text4')}}</p></div>
      <div class="intro_text">
        <span>5.</span>
        <p>{{$t('reward2.intro.text5')}}</p>
      </div>
      <div class="intro_text">
        <span>6.</span>
        <p>{{$t('reward2.intro.text6')}}</p>
      </div>
      <div class="intro_text">
        <span>7.</span>
        <p>{{$t('reward2.intro.text7')}}</p>
      </div>
      <div class="intro_text">
        <span>8.</span>
        <p>{{$t('reward2.intro.text8')}} <a href="https://orion.deeplink.cloud/" target="_blank">https://orion.deeplink.cloud/</a></p>
      </div>
    </div>
    
    <div class="node_table animation_hide" v-animate="{delay: 0, class:'fadeInUp'}">
      <div class="node_content" v-for="item in node_list">
        <div class="node_img">
          <div v-if="item.status == false" class="sold_out"><img class="status" src="../assets/image_img/sold_out.png" alt=""></div>
          <div v-if="item.status == true" class="on_sale"><img class="status" src="../assets/image_img/on_sale.png" alt=""></div>
          <img class="nft" :src="item.img" alt="">
        </div>
        <div class="node_info">
          <div class="title">
            <p>{{ $t('reward2.table.tier') }} {{ item.index }}</p>
            <div class="price">{{ item.price }} USDT</div>
          </div>
          <div class="text">
            <span>{{ $t('reward2.table.total_num') }}</span>
            <span class="price">{{ item.total }}</span>
          </div>
          <div class="text">
            <span>{{ $t('reward2.table.total_usdt') }}</span>
            <span class="price">{{ item.total_usdt }}</span>
          </div>
        </div>
      </div>
      <!-- <table class="table_cont">
        <tr class="theader">
          <th class="width60">{{ $t('reward2.table.tier') }}</th>
          <th class="">{{ $t('reward2.table.license') }}</th>
          <th class="">{{ $t('reward2.table.total_num') }}</th>
          <th class="">{{ $t('reward2.table.total_usdt') }}</th>
        </tr>
        <tr v-for="item in node_list">
          <th class="width60">{{item.index}}</th>
          <th class="">{{item.price}}</th>
          <th class="">{{item.total}}</th>
          <th class="">{{item.total_usdt}}</th>
        </tr>
      </table> -->
    </div>
    <div class="node_question">
      <template v-for="(item) in 13">
        <div class="question_title">{{ $t(`reward2.question.ques${item}`) }}</div>
        <div class="question_text">{{ $t(`reward2.question.ans${item}`) }}</div>
      </template>
    </div>
  </div>
</template>

<script>
  import { defineComponent, ref, computed } from "vue";
  import { useStore } from "vuex"
  import tier1 from "@/assets/tier1.png"
  import tier2 from "@/assets/tier2.png"
  import tier3 from "@/assets/tier3.png"
  import tier4 from "@/assets/tier4.png"
  import tier5 from "@/assets/tier5.png"
  import tier6 from "@/assets/tier6.png"
  import tier7 from "@/assets/tier7.png"
  import tier8 from "@/assets/tier8.png"
  import tier9 from "@/assets/tier9.png"
  import tier10 from "@/assets/tier10.png"
  export default defineComponent({
    name: 'Reward',
    setup() {
      const store = useStore()
      let lan = computed(() => {
        return store.state.lan
      })
      const node_list = ref([
        { index: 1, status: false, img: tier1,  price: 30  , total: 8000,  surplus: 8000,  total_usdt: 240000 },
        { index: 2, status: false, img: tier2,  price: 38  , total: 10000, surplus: 10000, total_usdt: 380000 },
        { index: 3, status: false, img: tier3,  price: 48  , total: 12000, surplus: 12000, total_usdt: 576000 },
        { index: 4, status: false, img: tier4,  price: 61  , total: 15000, surplus: 15000, total_usdt: 915000 },
        { index: 5, status: true, img: tier5,  price: 77  , total: 21000, surplus: 21000, total_usdt: 1617000 },
        { index: 6, status: null, img: tier6,  price: 89  , total: 16000, surplus: 16000, total_usdt: 1424000 },
        { index: 7, status: null, img: tier7,  price: 102 , total: 12000, surplus: 12000, total_usdt: 1224000 },
        { index: 8, status: null, img: tier8,  price: 117 , total: 10000, surplus: 10000, total_usdt: 1170000 },
        { index: 9, status: null, img: tier9,  price: 135 , total: 8000,  surplus: 8000,  total_usdt: 1080000 },
        { index: 10, status: null, img: tier10, price: 155 , total: 8000,  surplus: 8000,  total_usdt: 1240000 },
        // { index: 11, price: 44  , total: 3000, surplus: 3000, total_usdt: 130660 },   
        // { index: 12, price: 44  , total: 3000, surplus: 3000, total_usdt: 133273 },   
        // { index: 13, price: 45  , total: 3000, surplus: 3000, total_usdt: 135939 },   
        // { index: 14, price: 46  , total: 3000, surplus: 3000, total_usdt: 138657 },   
        // { index: 15, price: 47  , total: 3000, surplus: 3000, total_usdt: 141431 },   
        // { index: 16, price: 48  , total: 3000, surplus: 3000, total_usdt: 144259 },   
        // { index: 17, price: 49  , total: 3000, surplus: 3000, total_usdt: 147144 },   
        // { index: 18, price: 50  , total: 3000, surplus: 3000, total_usdt: 150087 },   
        // { index: 19, price: 51  , total: 3000, surplus: 3000, total_usdt: 153089 },   
        // { index: 20, price: 52  , total: 3000, surplus: 3000, total_usdt: 156151 },   
        // { index: 21, price: 53  , total: 2000, surplus: 2000, total_usdt: 106183 },   
        // { index: 22, price: 54  , total: 2000, surplus: 2000, total_usdt: 108306 },   
        // { index: 23, price: 55  , total: 2000, surplus: 2000, total_usdt: 110472 },   
        // { index: 24, price: 56  , total: 2000, surplus: 2000, total_usdt: 112682 },   
        // { index: 25, price: 57  , total: 2000, surplus: 2000, total_usdt: 114935 },   
        // { index: 26, price: 59  , total: 2000, surplus: 2000, total_usdt: 117234 },   
        // { index: 27, price: 60  , total: 2000, surplus: 2000, total_usdt: 119579 },   
        // { index: 28, price: 61  , total: 2000, surplus: 2000, total_usdt: 121970 },   
        // { index: 29, price: 62  , total: 2000, surplus: 2000, total_usdt: 124410 },   
        // { index: 30, price: 63  , total: 2000, surplus: 2000, total_usdt: 126898 },   
        // { index: 31, price: 65  , total: 2000, surplus: 2000, total_usdt: 129436 },   
        // { index: 32, price: 66  , total: 2000, surplus: 2000, total_usdt: 132025 },   
        // { index: 33, price: 67  , total: 2000, surplus: 2000, total_usdt: 134665 },   
        // { index: 34, price: 69  , total: 2000, surplus: 2000, total_usdt: 137358 },   
        // { index: 35, price: 70  , total: 2000, surplus: 2000, total_usdt: 140106 },   
        // { index: 36, price: 71  , total: 2000, surplus: 2000, total_usdt: 142908 },   
        // { index: 37, price: 73  , total: 2000, surplus: 2000, total_usdt: 145766 },   
        // { index: 38, price: 74  , total: 2000, surplus: 2000, total_usdt: 148681 },   
        // { index: 39, price: 76  , total: 2000, surplus: 2000, total_usdt: 151655 },   
        // { index: 40, price: 77  , total: 2000, surplus: 2000, total_usdt: 154688 },   
        // { index: 41, price: 79  , total: 1000, surplus: 1000, total_usdt: 78891  },   
        // { index: 42, price: 80  , total: 1000, surplus: 1000, total_usdt: 80469  },   
        // { index: 43, price: 82  , total: 1000, surplus: 1000, total_usdt: 82078  },   
        // { index: 44, price: 84  , total: 1000, surplus: 1000, total_usdt: 83720  },   
        // { index: 45, price: 85  , total: 1000, surplus: 1000, total_usdt: 85394  },   
        // { index: 46, price: 87  , total: 1000, surplus: 1000, total_usdt: 87102  },   
        // { index: 47, price: 89  , total: 1000, surplus: 1000, total_usdt: 88844  },   
        // { index: 48, price: 91  , total: 1000, surplus: 1000, total_usdt: 90621  },   
        // { index: 49, price: 92  , total: 1000, surplus: 1000, total_usdt: 92433  },   
        // { index: 50, price: 94  , total: 1000, surplus: 1000, total_usdt: 94282  },   
        // { index: 51, price: 96  , total: 1000, surplus: 1000, total_usdt: 96168  },   
        // { index: 52, price: 98  , total: 1000, surplus: 1000, total_usdt: 98091  },   
        // { index: 53, price: 100 , total: 1000, surplus: 1000, total_usdt: 100053 },   
        // { index: 54, price: 102 , total: 1000, surplus: 1000, total_usdt: 102054 },   
        // { index: 55, price: 104 , total: 1000, surplus: 1000, total_usdt: 104095 },   
        // { index: 56, price: 106 , total: 1000, surplus: 1000, total_usdt: 106177 },   
        // { index: 57, price: 108 , total: 1000, surplus: 1000, total_usdt: 108300 },   
        // { index: 58, price: 110 , total: 1000, surplus: 1000, total_usdt: 110466 },   
        // { index: 59, price: 113 , total: 1000, surplus: 1000, total_usdt: 112676 },   
        // { index: 60, price: 115 , total: 1000, surplus: 1000, total_usdt: 114929 }
      ])
      return {
        lan,
        node_list,
      };
    }
  })
</script>

<style lang="scss" scoped>
.reward {
  position: relative;
  width: 100%;
  z-index: 2;
  min-width: 600px;
  font-style: normal;
  padding: 80px 0;
  overflow: hidden;
  .reward_icon1 {
    position: absolute;
    left: 50%;
    top: -20px;
    width: 96px; 
    height: 90px;
    background: url(../assets/reward_icon1.png) no-repeat 100%/100%;
  }
  // .reward_icon2 {

  // }
  // .reward_icon3 {

  // }
  // .reward_icon4 {

  // }
  // .reward_icon5 {

  // }
  .node_desc {
    margin: auto;
    width: 90%;
    display: flex;
    flex-wrap: wrap;
    max-width: 1200px;
    justify-content: space-around;
    .desc_left {
      flex: 1;
      padding: 30px;
      max-width: 650px;
      min-width: 400px;
      margin: 0 10px 80px;
      border-radius: 30px;
      box-sizing: border-box;
      backdrop-filter: blur(6px);
      background: hsla(0, 0%, 100%, .1);
      border: 1px solid rgba(255, 255, 255, 0.25);
      .title {
        display: inline-block;
        font-size: 36px;
        font-weight: 600;
        font-family: Monda;
        color: transparent;
        margin-bottom: 25px;
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-image: linear-gradient(90deg, #8000FF 0%, #00FFD1);
      }
      .text {
        font-size: 16px;
        line-height: 30px;
        font-family: Outfit;
        color: rgba(255, 255, 255, 0.5);
      }
    }
    .desc_right {
      // width: 57%;
      padding: 30px;
      max-width: 680px;
      min-width: 400px;
      margin: 0 10px 80px;
      border-radius: 30px;
      box-sizing: border-box;
      backdrop-filter: blur(6px);
      background: hsla(0, 0%, 100%, .1);
      border: 1px solid rgba(255, 255, 255, 0.25);
      .title {
        width: 100%;
        color: #fff;
        padding: 16px;
        font-size: 24px;
        min-height: 57px;
        text-align: center;
        margin-bottom: 30px;
        border-radius: 17px;
        align-items: center;
        display: inline-flex;
        box-sizing: border-box;
        justify-content: center;
        backdrop-filter: blur(6px);
        background-color: rgb(255, 255, 255, .01);
      }
      .right_list {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        .right_li {
          width: 49%;
          padding: 16px;
          font-size: 18px;
          margin: 5px 0 0;
          text-align: center;
          align-items: center;
          font-family: Outfit;
          border-radius: 17px;
          display: inline-flex;
          box-sizing: border-box;
          justify-content: center;
          backdrop-filter: blur(6px);
          color: rgba(255, 255, 255, 0.5);
          background-color: rgb(255, 255, 255, .01);
        }
      }
    }
  }
  .node_video {
    width: 90%;
    height: auto;
    max-width: 1200px;
    margin: 0 auto 100px;
  }
  .btn-shadow {
    margin: auto;
    position: relative;
    padding: 30px 40px;
    border-radius: 58px;
    display: table;
    .btn {
      position: relative;
      z-index: 2;
      color: #fff;
      line-height: 1;
      cursor: pointer;
      font-size: 20px;
      font-weight: 600;
      padding: 18px 50px;
      border-radius: 58px;
      text-decoration: none;
      background: linear-gradient(90deg, #8000FF 0%, #03FFD2 100%);
    }
    .shadow {
      position: absolute;
      top: 50px;
      left: 50px;
      width: 100%;
      height: 100%;
      border-radius: 58px;
      animation: shadowAnim 3s ease-in-out infinite;
      background: linear-gradient(90.67deg, #7d05fe6b, #05e2bd66);
    }
    @keyframes shadowAnim {
      0% {
        top: 30px;
        left: 30px;
        background: linear-gradient(90.67deg, #05e2bd00, #7d05fe00);
      }
      40% {
        top: 0;
        left: 0;
        background: linear-gradient(90.67deg, #05e2bd66, #7d05fe6b);
      }
      50% {
        top: 10px;
        left: 0;
        background: linear-gradient(90.67deg, #05e2bd66, #7d05fe6b);
      }
      60% {
        top: -10px;
        left: 0;
        background: linear-gradient(90.67deg, #05e2bd66, #7d05fe6b);
      }
      70% {
        top: 0;
        left: 0;
        background: linear-gradient(90.67deg, #05e2bd66, #7d05fe6b);
      }
      100% {
        top: 0;
        left: 0;
        background: linear-gradient(90.67deg, #05e2bd00, #7d05fe00);
      }
    }
  }
  .buy_nodes {
    margin: auto;
    color: #fff;
    display: table;
    font-size: 24px;
    font-weight: 600;
    padding: 18px 50px;
    border-radius: 14px;
    text-decoration: none;
    background: linear-gradient(90deg, #8000FF 0%, #03FFD2 100%);
  }
  .node_intro {
    width: 90%;
    max-width: 1200px;
    padding: 50px;
    border-radius: 30px;
    box-sizing: border-box;
    margin: 80px auto 100px;
    backdrop-filter: blur(6px);
    background: hsla(0, 0%, 100%, .1);
    .intro_title {
      color: #fff;
      font-size: 36px;
      font-weight: 600;
      font-family: Monda;
      margin-bottom: 30px;
    }
    .intro_text {
      display: flex;
      font-size: 16px;
      line-height: 30px;
      font-family: Outfit;
      margin-bottom: 20px;
      color: rgba(255, 255, 255, 0.5);
      span {
        margin-right: 5px;
      }
      &:last-child {
        margin-bottom: 0;
      }
      p {
        white-space: break-spaces;
      }
    }
  }
  .node_table {
    width: 90%;
    display: flex;
    flex-wrap: wrap;
    // overflow: auto;
    max-width: 1200px;
    margin: 0px auto 100px;
    justify-content: space-between;
    .node_content {
      width: 230px;
      margin-bottom: 40px;
      border-radius: 21px;
      background: hsla(0deg, 0%, 100%, 0.1);
      .node_img {
        position: relative;
        width: 230px;
        height: 232px;
        img.nft {
          width: 230px;
          height: 232px;
        }
        .sold_out {
          position: absolute;
          left: 2px;
          bottom: 30px;
          width: 90px;
          height: 35px;
          display: flex;
          align-items: center;
          background: #C10000;
          border-radius: 0 10px 10px 0;
          justify-content: center;
          img.status {
            width: 60px;
          }
        }
        .on_sale {
          position: absolute;
          left: 2px;
          bottom: 30px;
          width: 90px;
          height: 35px;
          display: flex;
          align-items: center;
          background: #03FF91;
          border-radius: 0 10px 10px 0;
          justify-content: center;
          img.status {
            width: 60px;
          }
        }
      }
      .node_info {
        width: 100%;
        padding: 0 16px 16px;
        box-sizing: border-box;
        .title {
          width: 100%;
          color: #fff;
          display: flex;
          padding: 9px 0;
          font-size: 14px;
          font-weight: 600;
          align-items: center;
          margin-bottom: 10px;
          justify-content: space-between;
          border-bottom: 1px solid hsla(0deg, 0%, 100%, 0.1);
          .price {
            color: #000;
            font-size: 10px;
            padding: 3px 10px;
            border-radius: 4px;
            background: #00FF95;
            display: inline-block;
          }
        }
        .text {
          width: 100%;
          display: flex;
          margin: 5px 0;
          font-size: 12px;
          align-items: center;
          justify-content: space-between;
          color: rgba(255, 255, 255, .5);
        }
      }
    }
    .table_cont {
      width: 100%;
      color: #fff;
      font-size: 16px;
      font-weight: 400;
      line-height: 150%;
      overflow: hidden;
      border-collapse: collapse;
      border-radius: 10px 10px 0 0;
      tr.theader {
        border-radius: 10px;
        backdrop-filter: blur(6px);
        background: hsla(0, 0%, 100%, .1);
        th {
          border: none;
          padding: 10px;
        }
      }
      th {
        padding: 5px 10px;
        text-align: right;
        font-weight: 400;
        border-bottom: 1px solid rgba(255, 255, 255, 0.2);
      }
      td {
        padding: 10px 10px;
        border-bottom: 1px solid rgba(255, 255, 255, 0.2);
      }
      .font16 {
        font-size: 16px;
        font-weight: 400;
        color: #899AA9;
        line-height: 150%;
      }
      .buy_btn {
        color: #fff;
        font-size: 16px;
        cursor: pointer;
        font-weight: 400;
        padding: 2px 25px;
        border-radius: 8px;
        background: #aaa;
        // background: #03C439;
        display: inline-block;
      }
      .width60 {
        width: 60px;
        text-align: center;
        box-sizing: border-box;
      }
      .width150 {
        width: 150px;
        text-align: center;
        box-sizing: border-box;
      }
    }
  }
  .node_question {
    width: 90%;
    padding: 50px;
    max-width: 1200px;
    border-radius: 30px;
    box-sizing: border-box;
    margin: 80px auto 100px;
    backdrop-filter: blur(6px);
    background: hsla(0, 0%, 100%, .1);
    .question_title {
      color: #fff;
      font-size: 22px;
      font-weight: 600;
      font-family: Monda;
      margin-bottom: 20px;
    }
    .question_text {
      display: flex;
      font-size: 16px;
      line-height: 30px;
      font-family: Outfit;
      margin-bottom: 20px;
      color: rgba(255, 255, 255, 0.5);
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}

.lan_En.reward {
  .content {
    // word-break: break-all;
    &.content2 {
      height: inherit;
      background-size: 100% 100%;
      // height: 1100px;
      // background-size: 100% 1100px;
    }
    .cont1 {
      .tips {
        .tip2 {
          top: 90px;
        }
        .tip3 {
          top: 10px;
        }
      }
    }
    .cont2 {
      .icon2 {
        top: 750px;
      }
      .desc3 {
        padding: 25px 5px 0;
        .tip1 {
          font-size: 16px;
          line-height: 24px;
        }
        .tip2 {
          font-size: 12px;
          line-height: 18px;
        }
      }
      .line {
        height: 80px;
      }
      .branch {
        margin: 25px auto 0;
      }
      .tree {
        .leaf {
          .line1 {
            height: 30px;
          }
          .icon {
            margin-bottom: 10px;
          }
          .text {
            height: 220px;
            padding: 10px 5px;
            font-size: 12px;
            line-height: 14px;
            p {
              margin-bottom: 0;
            }
            .title {
              font-size: 14px;
              line-height: 16px;
              margin-bottom: 10px;
            }
          }
        }
        .group {
          position: absolute;
          bottom: -30px;
          width: 58px;
          height: 12px;
        }
        .group1 {
          left: -15px;
        }
        .group2 {
          right: -15px;
        }
      }
    }
    .cont3 {
      p {
        margin-bottom: 5px;
      }
      .table {
        height: 160px;
        .li {
          height: 160px;
          padding: 10px;
          .title {
            line-height: 18px;
          }
          .desc {
            line-height: 14px;
          }
        }
      }
    }
    .cont4 {
      .desc2 {
        .tip {
          height: 150px;
          padding: 10px 10px 0;
        }
      }
    }
    .cont6 {
      .contimg {
        .block1 {
          .span {
            padding: 25px 5px 0;
            line-height: 14px;
            .title {
              font-size: 14px;
              line-height: 18px;
            }
          }
        }
        .block2 {
          .span {
            padding: 50px 20px 0;
            .title {
              font-size: 22px;
            }
          }
          .active {
            padding: 50px 20px 0;
            .title {
              font-size: 22px;
            }
          }
        }
        .block3{
          .span {
            padding: 30px 5px 0;
            line-height: 14px;
            .title {
              font-size: 14px;
              line-height: 18px;
            }
          }
        }
      }
      .details {
        .detail_con {
          .block {
            .title {
              font-size: 18px;
              line-height: 24px;
            }
            .text1 {
              font-size: 12px;
              line-height: 16px;
            }
            &.text {
              padding: 10px;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1400px){
  .reward {
    .content {
      .cont1 {
        padding: 100px 0 0 50px;
        background-position: 610px 130px;
        .icon1 {
          top: -20px;
          left: 100px;
        }
        .icon2 {
          left: 930px;
        }
        .icon3 {
          left: 1000px;
        }
        .text {
          width: 400px;
        }
        .tips {
          width: 600px;
          left: 530px;
        }
      }
      .cont2 {
        .icon1 {
          left: 50px;
        }
        .icon2 {
          left: 1000px;
        }
        .details {
          width: 570px;
        }
      }
    }
  }
  .lan_En.reward {
    .content {
      .cont1 {
        .tips {
          .tip2 {
            left: 25%;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1200px){
  .reward {
    .content {
      position: relative;
      margin-top: -2px;
      width: 100%;
      font-size: 0;
      line-height: 0;
      &.content1 {
        height: inherit;
        background: url('../assets/reward_bg1.png') no-repeat center;
        background-size: 100% 100%;
      }
      &.content2 {
        height: inherit;
        background: linear-gradient(180deg, #363F5E 52.12%, rgba(54, 63, 94, 0.9) 100%);
      }
      .show {
        display: block;
      }
      .hide {
        display: none;
      }
      .cont1 {
        position: relative;
        margin: auto;
        min-width: unset;
        width: 100%;
        text-align: center;
        font-weight: 500;
        height: inherit;
        overflow: hidden;
        padding: 0 0 130px;
        background: url("../assets/reward.png") no-repeat center;
        background-size: 400px 400px;
        background-position-y: 150px;
        .icon1 {
          position: absolute;
          top: 70px;
          left: 60px;
          width: 112px;
          height: 112px;
          transform: rotate(40deg);
          background: url('../assets/icon1.png') no-repeat 100%/100%;
        }
        .icon2 {
          position: absolute;
          top: 93px;
          right: 100px;
          left: unset;
          width: 133px;
          height: 133px;
          background: url('../assets/icon2.png') no-repeat 100%/100%;
          opacity: .6;
        }
        .icon3 {
          position: absolute;
          top: 668px;
          left: unset;
          right: 60px;
          width: 133px;
          height: 133px;
          background: url('../assets/icon3.png') no-repeat 100%/100%;
          opacity: .6;
        }
        .desc1 {
          width: 85%;
          margin: auto;
        }
        .tips {
          position: relative;
          top: unset;
          left: unset;
          width: 100%;
          margin-top: 400px;
          .tip1 {
            left: 10%;
          }
          .tip2 {
            position: absolute;
            left: 30%;
            top: 110px;
            margin: auto;
            display: flex;
            padding: 12px 56px;
            background: #FFF;
            border-radius: 296px;
            flex-direction: column;
            box-shadow: 0px 6px 30px rgba(54, 63, 94, 0.1);
          }
          .tip3 {
            left: unset;
            right: 10%;
            top: 30px;
          }
          .tip4 {
            top: 220px;
            left: 15%;
          }
          .tip5 {
            top: 230px;
            right: 15%;
            left: unset;
          }
        }
        .btn {
          margin: 340px 0 20px;
        }
        .title {
          font-size: 30px;
          margin-top: 40px;
        }
        .text {
          width: 100%;
          margin: auto;
        }
      }
      .cont2 {
        position: relative;
        margin: auto;
        width: 80%;
        padding-top: 40px;
        text-align: left;
        justify-content: center;
        .icon1 {
          display: none;
        }
        .icon2 {
          display: none;
        }
        p {
          margin-bottom: 5px;
        }
        .desc1 {
          font-weight: 600;
          font-size: 24px;
          line-height: 42px;
          color: #FFFFFF;
        }
        .desc2 {
          font-weight: 400;
          font-size: 16px;
          line-height: 22px;
          margin-bottom: 20px;
          color: rgba(255, 255, 255, 0.88);
        }
        .desc3 {
          width: 420px;
          height: 120px;
          margin: auto;
          font-weight: 600;
          padding-top: 27px;
          text-align: center;
          box-sizing: border-box;
          background: url(../assets/tree.png) no-repeat 100%/100%;
          .tip1 {
            color: #FFF;
            font-size: 24px;
            line-height: 33px;
          }
          .tip2 {
            font-size: 18px;
            line-height: 25px;
            color: #44FF55;
          }
        }
        .line {
          position: absolute;
          top: 250px;
          left: 0;
          right: 0;
          width: 3px;
          margin: auto;
          height: 50px;
          background: url(../assets/line.png) no-repeat 100%/100%;
        }
        .branch {
          position: relative;
          width: 64%;
          height: 0;
          margin: 50px auto 0;
          border-top: 2px dashed rgba(4, 180, 116, 0.68);
        }
        .tree {
          position: relative;
          width: 100%;
          display: flex;
          margin: auto;
          flex-wrap: wrap;
          justify-content: center;
          .leaf {
            width: 28%;
            margin: 0 2%;
            text-align: center;
            &.leaf2 {
              margin-top: -300px;
            }
            .line1 {
              width: 3px;
              margin: auto;
              height: 51px;
              background: url(../assets/line.png) no-repeat 100%/100%;
            }
            .line2 {
              height: 330px;
              background-repeat: repeat-y;
            }
            .icon {
              width: 52px;
              height: 52px;
              margin-bottom: 15px;
            }
            .text {
              width: 100%;
              height: 180px;
              padding: 10px;
              font-weight: 400;
              font-size: 12px;
              line-height: 18px;
              text-align: justify;
              border-radius: 12px;
              box-sizing: border-box;
              border: 1px solid rgba(255, 255, 255, 0.45);
              color: rgba(255, 255, 255, 0.6);
              background: linear-gradient(0deg, rgba(255, 255, 255, 0.16) 0%, rgba(255, 255, 255, 0.016) 96.01%);
              .title {
                width: 100%;
                height: 40px;
                font-weight: 600;
                font-size: 16px;
                line-height: 20px;
                text-align: center;
                color: #44FF55;
                margin-bottom: 10px;
              }
            }
          }
        }
      }
    }
  }
  .lan_En.reward {
    .content {
      &.content2 {
        height: inherit;
        background: linear-gradient(180deg, #363F5E 52.12%, rgba(54, 63, 94, 0.9) 100%);
      }
      .cont1 {
        .tips {
          .tip2 {
            left: 25%;
          }
        }
      }
    }
  }
}

</style>

